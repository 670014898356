@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@423&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap);
@font-face {
	font-family: cerar;
	src: url("/static/media/Cera Pro Regular.0cd32798.otf");
}

@font-face {
	font-family: cerab;
	src: url("/static/media/Cera Pro Bold.07ac8153.otf");
}
body {
	margin: 0;
	overflow-x: hidden;
	color: #292f36;
	font-family: 'Source Sans Pro';
}

/* Main Section Start */
a {
	color: #000;
	text-decoration: none;
}

.totop {
	display: none !important;
}

.nav{
	height: 80px;
	background: #ffffff;
	position: fixed;
	top: 0;
	width: 100vw;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	font-weight: 570;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 4;
}

.links {
	display: flex;
	margin-right: 40px;
}

.links div {
	margin: 0 13px;
	color: #222;
	cursor: pointer;
	transition: color 0.3s ease;
}

.nav2{
	display: none;
}

.links div:hover {
	color: #f46767;
}
.background {
	width: 100vw;
	height: 80vh;
	background: url(/static/media/background2.c43da60a.jpg);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 80px;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: visible;
}

.overlay {
	background: #000;
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
}

.countries {
	background: #fff;
	width: 55vw;
	height: 60px;
	z-index: 2;
	position: relative;
	top: 40px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	font-family: 'Josefin Sans', sans-serif;
	justify-content: space-around;
	padding: 2px 20px 0 20px;
	font-size: 17px;
	z-index: 3;
	position: relative;
}

.know {
	font-weight: 700;
	font-size: 16px;
}

.logo {
	width: 180px;
	height: 60px;
	background: url(/static/media/logo.dad506f9.jpg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: 30px;
}

.heading {
	color: #fff;
	font-size: 40px;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
	letter-spacing: 3px;
	width: 100vw;
	text-align: center;
	position: absolute;
	bottom: 20px;
}

.center {
	height: 80%;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	left: 0;
}
.circle {
	display: none;
	width: 230px;
	height: 230px;
	position: absolute;
	z-index: 2;
	background: linear-gradient(90deg, rgba(242, 64, 64, 1) 0%, rgba(244, 103, 103, 1) 100%);
	border-radius: 200px;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.circle1 {
	right: -00px;
	top: -70px;
}

.circle2 {
	top: -100px;
	right: -80px;
}

.circle3 {
	top: -100px;
	right: 80px;
}

.ham {
	display: none;
}
/* Main Section End */

/* About Section Start */
.container {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.about {
	width: 65vw;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Source Sans Pro', sans-serif;
}

.sectionHead {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 45px;
	font-weight: 700;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.line {
	width: 70px;
	height: 2px;
	background: linear-gradient(90deg, rgba(242, 64, 64, 1) 0%, rgba(244, 103, 103, 1) 100%);
}

.atext {
	font-size: 20px;
	margin: 0 20px;
	text-align: justify;
	font-family: 'Source Sans Pro';
}

.quote {
	margin-top: 20px;
	font-weight: 700;
	font-style: italic;
	text-align: center;
	letter-spacing: 1px;
}

.images {
	width: 100vw;
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.li {
	width: 100vw;
	height: auto;
	display: flex;
	justify-content: space-around;
	position: relative;
}

.li2 {
	width: 60vw;
}

.bar {
	width: 100%;
	height: 150px;
	background: linear-gradient(90deg, rgba(242, 64, 64, 1) 0%, rgba(244, 103, 103, 1) 100%);
	position: absolute;
	z-index: -1;
	top: 60px;
}

.box {
	text-align: center;
	width: 290px;
	font-family: 'Source Sans Pro';
	letter-spacing: 1px;
	margin-bottom: 30px;
}

.img {
	height: 290px;
	width: 290px;
}

.members {
	width: 80vw;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.mname {
	font-weight: 700;
	font-size: 24px;
	text-transform: uppercase;
	margin-top: 8px;
}

.mpost {
	font-weight: 600;
	font-size: 17px;
}

.mtext {
	margin-top: 10px;
	font-family: ;
	font-size: 16px;
	text-align: justify;
	font-weight: 500;
	letter-spacing: 1px;
	/* font-family:Helvetica, sans-serif; */
}

.tea {
	margin-bottom: 30px;
}
/* About Section End */

/* Service Section Start */

.points {
	margin: 0 10vw;
	display: flex;
	margin-top: 15px;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.points ol {
	font-size: 24px;
	margin: 10px 50px;
	font-weight: ;
	text-align: justify;
}

.point {
	width: 850px;
	padding: 30px 30px;
	margin: 16px 0;
	background: #fff;
	border-radius: 10px;
	font-size: 20px;
	text-align: center;
	margin: 16px 20px;

	box-shadow: 0px 0px 12px 0 rgba(185, 89, 89, 0.4);
}

/* Service Section End */

/* Explore Section Start */

.l2 {
	margin-top: 5px;
}

.exp {
	margin-top: 30px;
}

.cbox {
	width: 250px;
	height: 250px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	margin: 20px 20px;
	border-radius: 8px;
}

.clist {
	width: 80vw;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 20px 10vw 0 10vw;
}

.ph {
	width: 230px;
	height: 230px;
	margin-left: 10px;
	border-radius: 5px;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	top: 10px;
}

.photo {
	width: 230px;
	height: 230px;
	transform: scale(1);
	transition: transform 0.5s ease;
}

.ph:hover > .photo {
	transform: scale(1.08);
}

.cname {
	position: absolute;
	z-index: 1;
	color: #fff;
	font-family: Josefin Sans;
	font-weight: 700;
	font-size: 35px;
}

/* Contact */

.con {
	margin-top: 30px;
}

.info {
	width: 50%;
	margin: 0 auto;
	font-size: 24px;
	letter-spacing: 1px;
}

.in {
	font-family: 'Source Sans Pro';
	font-weight: 700;
	margin: 0 10px;
	margin-top: 10px;
}

.phones {
	margin-left: 53px;
	margin-top: 10px;
	font-size: 26px;
}

.num {
	margin-left: 53px;
}

.rot {
	transform: rotate(90deg);
}

/* Contact end */

/* Explore Section End */

/* Country */

.cback {
	width: 100vw;
	height: 85vh;
	/* margin-top:80px; */
}

.sp {
	margin-top: 50px;
}

.spa {
	margin-top: 10px;
}

.ltext {
	margin: 20px 12vw 0 12vw;
	text-align: justify;
	font-size: 23px;
}

.ltext div {
	margin: 20px 0;
}

.bol {
	font-weight: bolder;
}

.cen {
	text-align: center;
}

.ques {
	padding-top: 15px;
}

li {
	margin: 10px 0;
}

.ff {
	margin: 40px 0;
	border-radius: 10px;
	padding: 20px 20px 25px 20px;
	box-shadow: 0px 0px 12px 0 rgba(185, 89, 89, 0.4);
}

.ff div {
	font-size: 23px;
	margin: 5px 0 15px 0;
}

.toback {
	color: #000;
	font-size: 23px;
	font-weight: 700;
	margin: 20px;
	position: fixed;
	background: #fff;
	padding: 10px 30px;
	border-radius: 50px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

/* Country End */

@media only screen and (max-width: 1200px) {
	.countries {
		width: 55vw;
	}

	.point {
		width: 80vw;
	}
}

@media only screen and (max-width: 1000px) {
	.countries {
		width: 85vw;
	}

	.circle {
		height: 200px;
		width: 200px;
	}

	.bar {
		background: none;
	}
	.point {
		width: 90vw;
		font-size: 16px;
		line-height: normal;
	}

	.info {
		width: 80%;
	}
}

@media only screen and (max-width: 650px) {
	.ham {
		display: block;
		font-size: 30px;
		position: absolute;
		left: 20px;
	}
	
	.nav2 {
		padding-top: 30px;
		justify-content: center;
		align-items: flex-start;
		height: 60px;
		background: rgba(255, 255, 255, 1);
	}

	.nav2 .links {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 24px;
		top: 200px;
		margin-right: 0;
	}

	.nav2 .links div {
		display: none;
		margin: 10px 0;
	}

	.logo {
		margin-top: -10px;
		margin-left: 0;
		height: 50px;
		width: 140px;
	}
	.nav1{
		display: none;
	}

	.nav2{
		display: flex;
	}

	.totop {
		display: flex !important;
		position: fixed;
		width: 20px;
		height: 20px;
		bottom: 10px;
		right: 10px;
		padding: 13px;
		font-size: 23px;
		align-items: center;
		justify-content: center;
	}
	.background {
		height: 70vh;
	}

	.cback {
		height: 70vh;
	}
	.countries {
		width: 100vw;
		top: 0;
		border-radius: 0;
		font-size: 14px;
		justify-content: center;
	}

	.know {
		font-size: 14px;
	}

	.about {
		width: 90vw;
	}

	.countries div {
		font-size: 20px;
		margin: 0 3px;
	}
	.nav {
		font-size: 14px;
		box-shadow: none;
	}

	.links div {
		margin: 0 5px;
	}

	.heading {
		width: 80vw;
		font-size: 20px;
	}

	.know {
		display: none;
	}

	.point {
		font-size: 16px;
	}
	.info {
		font-size: 18px;
	}

	.phones {
		font-size: 18px;
	}
}

@media only screen and (max-width: 500px) {
	.countries div {
		font-size: 14px;
	}

	.circle {
		height: 100px;
		width: 100px;
	}

	.circle1 {
		left: 20px;
		bottom: -40px;
	}

	.circle2 {
		right: 0;
		left: 0;
		bottom: -40px;
		margin: 0 auto;
	}

	.circle3 {
		right: 20px;
		bottom: -40px;
	}

	.about {
		margin-top: 100px;
	}

	.heading {
		text-align: center;
	}
	.info {
		width: 100%;
		font-size: 16px;
		letter-spacing: 0;
	}
}

.designed{
	font-family: cerar;
	text-align: center;
	padding: 30px 0;
	letter-spacing: 1px;
	font-size: 18px;
	width: 100vw;
}

.designed span{
	font-family: cerab;
	color: #6457a6;
}

